.buttonContainer {
  background-color: var(--metallic-blue-color);
  box-shadow: 0px 4px 16px var(--ghost-white-color);
  border-radius: 10px;
  height: 45px;
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0px 20px;
}

button:disabled,
button[disabled] {
  /* border: 1px solid #999999; */
  background-color: var(--light-grey-color);
  opacity: 0.65;
  /* color: #666666; */
}

.buttonTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: var(--white-color);
}
