.buttonContainer {
  background-color: var(--metallic-blue-color);
  box-shadow: 0px 4px 16px var(--ghost-white-color);
  border-radius: 16px;
  height: 45px;
  gap: 10px;
  width: 190px;
  margin: 35px 12px 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.buttonTitle {
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--white-color);
}
