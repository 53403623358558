body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

.form-check-input:focus {
  border-color: rgb(184, 184, 184) !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.dropdown-item {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 12px 15px !important;
}

.dropdown-menu {
  border: none !important;
  padding: 0px 0px !important;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
  margin-top: 5px !important;
}

.dropdown-item:hover {
  border-radius: 10px !important;
  background-color: var(--dark-grey-color) !important;
  color: var(--black-color);
}

.modal-content {
  border: none !important;
  outline: none !important;
  border-radius: 24px !important;
}

.modal-body {
  padding: 0px !important;
  border-radius: 24px !important;
}
