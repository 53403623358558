.didFloatingLabelContent {
  position: relative;
  margin-bottom: 20px;
}

.didFloatingLabel {
  color: var(--grey-color);
  background-color: var(--white-color);
  display: none;
  position: absolute;
  pointer-events: none;
  left: 12px;
  font-size: 13px;
  top: 12px;
  padding: 0 5px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.didFloatingInput:focus ~ .didFloatingLabel {
  color: var(--dark-blue-color);
  top: -11px;
  font-size: 13px;
  font-weight: 700;
  display: block;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.didFloatingInput {
  border: 1px solid var(--grey-color);
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--dark-blue-color);
  box-shadow: 0 0 0 30px white inset !important;
}
.didFloatingInput:focus {
  border: 1px solid var(--dark-blue-color);
  outline: none;
}
.star {
  color: var(--red-color);
  margin-left: 2px;
}
