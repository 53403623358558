.mainContainer {
  display: flex;
  height: 100%;
}
.bodyContainer {
  width: 100%;
  padding: 20px;
  overflow: auto;
  background-color: var(--white-color);
}
