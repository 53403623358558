.assignees {
  width: 200px;
  margin: auto;
  display: flex;
  text-align: left;
}

.searchContainer {
  padding-top: 35px;
  width: 250px;
}

.searchWrap {
  display: flex;
}
