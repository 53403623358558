.modalHeader {
  background-color: var(--pearl-black-color);
  padding: 22px 25px;
  border-radius: 20px 20px 0px 0px;
}

.contentWrapper {
  padding: 40px 20px;
}

.modalButtonWrapper {
  margin-top: 40px;
}

.modalTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--white-color);
}

.modalButtonWrapper .tooltipText {
  visibility: hidden;
  width: 130px;
  font-size: 12px;
  font-weight: 500;
  color: rgb(133, 19, 19);
  text-align: center;
  position: absolute;
  bottom: 120%;
  left: 40%;
  margin-left: -50px;
  z-index: 1;
}

.modalButtonWrapper:hover .tooltipText {
  visibility: visible;
}
