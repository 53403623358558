.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
.headerTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--light-grey-color);
}
.bgAvatar {
  background-color: var(--pearl-black-color);
  width: 42px;
  height: 42px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.avatar {
  width: 20px;
  height: 20px;
}
