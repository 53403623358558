.assigneesWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 10px;
}
.commissionWrapper {
  display: flex;
  align-items: center;
}
.assigneeName {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--light-grey-color);
}
.assigneeCommission {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  color: var(--grey-color);
}
.commissionPrice {
  margin-left: 12px;
}

.commissionInput {
  border: 1px solid var(--grey-color);
  width: 100px;
  height: 30px;
  padding-left: 9px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}

.assigneeCommission > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.assigneeCommission > input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.percentageCommission {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--light-grey-color);
}
.closeIcon {
  margin-left: 36px;
  cursor: pointer;
}
