.viewProfile {
  color: var(--light-grey-color);
}
.viewProfile:hover {
  color: var(--light-grey-color);
}
.searchContainer {
  padding-top: 35px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.searchWrap {
  display: flex;
}
