.buttonContainer {
  border-radius: 10px;
  height: 35px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--off-white-color);
  padding: 0px 10px;
}

.buttonTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--light-grey-color);
}

.links {
  text-decoration: none;
}
